<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="注册申请"
      class="px-5 py-3 mb-5"
    >
      <v-row class="mt-5">
        <v-col md="2">
          <v-text-field
            v-model="searchParams.nickname"
            label="昵称"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="searchParams.mobile"
            label="手机号"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col md="1">
          <v-btn
            color="success"
            block
            @click="getRegApply"
          >
            <v-icon left>
              fa fa-search
            </v-icon>
            搜索
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th>
              <v-checkbox />
            </th>
            <th>昵称</th>
            <th>手机号</th>
            <th>审核状态</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(user, index) in RegApply"
            :key="index"
          >
            <td width="30">
              <v-checkbox />
            </td>
            <td>{{ user.data.name }}</td>
            <td>{{ user.data.tel }}</td>
            <td>{{ user.status | stateToName }}</td>
            <td class="text-right">
              <v-btn
                class="mx-2"
                small
                color="success"
                @click="adopt(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-check
                </v-icon>
                通过
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="error"
                @click="reject(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-times
                </v-icon>
                驳回
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="info"
                @click="ignore(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-eye-slash
                </v-icon>
                忽略
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="warning"
                @click="seeInfo(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-eye-slash
                </v-icon>
                注册信息
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <!-- <v-row class="mt-5">
        <v-col md="12">
          <v-btn
            outlined
            color="success"
            class="mr-2"
          >
            <v-icon left>
              fa fa-check
            </v-icon>
            批量通过
          </v-btn>
          <v-btn
            outlined
            color="error"
            class="mr-2"
          >
            <v-icon left>
              fa fa-times
            </v-icon>
            批量驳回
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="6"
        />
      </div> -->
    </base-material-card>
  </div>
</template>

<script>
  import http from '../../api'
  export default {
    data () {
      return {
        page: 1,
        actions: [
          {
            color: 'success',
            icon: 'mdi-pencil',
          },
          {
            color: 'error',
            icon: 'mdi-close',
          },
        ],
        dialog: false,
        RegApply: [],
        searchParams: {
          nickname: '',
          mobile: '',
        },
      }
    },
    created () {
      this.getRegApply()
    },
    methods: {
      getRegApply () {
        const params = {
          formId: '1de2b7c2-f818-4c5d-a25e-3be787e7d92b',
          status: 5,
        }
        if (this.searchParams.nickname !== '') {
          params.name = this.searchParams.nickname
        }
        if (this.searchParams.mobile !== '') {
          params.tel = this.searchParams.mobile
        }
        http.report.queryReportList(params)
          .then(res => {
            this.RegApply = res.data.data.content
          })
          .catch(err => {
            console.log(err)
          })
      },
      adopt (index) {
        http.report.auditReport(this.RegApply[index].id, {
          attachments: null,
          message: null,
          status: 10,
        })
          .then(res => {
            this.getRegApply()
          })
      },
      reject (index) {
        http.report.auditReport(this.RegApply[index].id, {
          attachments: null,
          message: null,
          status: -5,
        })
          .then(res => {
            this.getRegApply()
          })
      },
      ignore (index) {
        this.$swal({
          title: '你确定要忽略吗？',
          text: '忽略后将无法再次审核该申请。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            http.report.deleteReport(this.RegApply[index].id)
              .then(res => {
                this.getRegApply()
                this.$swal({
                  title: '申请已忽略',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch(err => {
                this.$swal({
                  title: '忽略失败',
                  icon: 'error',
                  text: '原因:' + err.response.data.msg,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
      seeInfo (index) {},
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
</style>
